/* You can add global styles to this file, and also import other style files */

@import "./theme.scss";

.wizard-nav {
  position: fixed !important;
  left: 0;
  right: 0;
  bottom: 0;
  height: 40px;
}
dv-wizardstep > mat-card button {
  margin: 0 5px 0 5px 0;
}
.next-btn,
dv-wizardstep > mat-card button:first-child {
  margin-right: 80px !important;
}

@use '@angular/material' as mat;
@import "@angular/material/_theming";

// Include the base styles for Angular Material core. We include this here so that you only
// have to load a single css file for Angular Material in your app.
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$dvwebb-app-primary: mat.define-palette(mat.$blue-palette, 500);
//$dvwebb-app-primary: mat-color(#0093ee);
$dvwebb-app-accent: mat.define-palette(mat.$green-palette, A700);

// The warn palette is optional (defaults to red).
$dvwebb-app-warn: mat.define-palette(mat.$red-palette);

$dvwebb-light-theme-foreground: (
  base: #3f536e,
  divider: #7e95a8,
  dividers: rgba(#3f536e, 0.12),
  disabled: rgba(#3f536e, 0.38),
  disabled-button: rgba(#3f536e, 0.38),
  disabled-text: rgba(#3f536e, 0.38),
  hint-text: rgba(#3f536e, 0.38),
  secondary-text: #8dabc4,
  icon: rgba(#3f536e, 0.54),
  icons: rgba(#3f536e, 0.54),
  text: rgba(#3f536e, 0.87),
  menu: #aab2bd,
);
$dwebb-light-theme-background: (
  status-bar: map-get(mat.$grey-palette, 300),
  app-bar: map-get(mat.$grey-palette, 100),
  //   background: rgba(183, 210, 229,1),
  background: #ecf5fd,
  hover: rgba(black, 0.04),
  // TODO(kara): check style with Material Design UX
  card: white,
  dialog: white,
  disabled-button: rgba(black, 0.12),
  menu: #2c405a,
);

$dvwebb-foreground: map-merge(
  mat.$light-theme-foreground-palette,
  $dvwebb-light-theme-foreground
);
$dvwebb-background: map-merge(
  mat.$light-theme-background-palette,
  $dwebb-light-theme-background
);

// Create the theme object (a Sass map containing all of the palettes).
$dvwebb-app-theme: (
  primary: $dvwebb-app-primary,
  accent: $dvwebb-app-accent,
  warn: $dvwebb-app-warn,
  is-dark: false,
  foreground: $dvwebb-foreground,
  background: $dvwebb-background,
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

@include mat.all-component-themes($dvwebb-app-theme);

.mat-raised-button.mat-accent {
  color: #fff !important;
}

body {
  font-family: "Roboto", sans-serif;
  overflow: hidden;

  // Helps fonts on OSX looks more consistent with other systems
  // Isn't currently in button styles due to performance concerns
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
h2 {
  margin-bottom: 0;
}

mat-card.nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

mat-icon.size-16 {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
mat-icon.size-32 {
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 32px;
}
mat-icon.size-64 {
  width: 64px;
  height: 64px;
  line-height: 64px;
  font-size: 64px;
}
mat-icon.size-128 {
  width: 128px;
  height: 128px;
  line-height: 128px;
  font-size: 128px;
}
.mat-dialog-container {
  overflow: visible !important;
}
